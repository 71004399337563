.Settings {
    height: 100vH;
}

.perfect-center {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;
}

ListItemIcon {
    min-width: 0px;
}

Divider, hr {
    width: 100vW;
    height: 0px;
    background: #cccccc;
}