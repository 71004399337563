.Privacy {
    padding: 100px 30px;
    body {
        font-family: Arial, sans-serif;
        line-height: 1.6;
        margin: 20px;
        padding: 20px;
        background-color: #f9f9f9;
    }
    h1, h2, h3 {
        color: #333;
    }
    h1 {
        font-size: 24px;
        margin-bottom: 20px;
    }
    h2 {
        font-size: 20px;
        margin-top: 20px;
    }
    p {
        margin: 10px 0;
    }
    ul {
        margin: 10px 0 10px 20px;
    }
}