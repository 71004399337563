.MainComponent {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.mainLogo {
  width: 300px;
  margin: 15px auto;
}
.perfect-center {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.chip-cover {
  chip {
    width: 100%;
  }
}

.FontAwesomeIcon {
  margin-right: 10px;
}

svg {
  vertical-align: -webkit-baseline-middle;
}

body {
  margin: 0;
  padding: 0;
}
.hero {
  aspect-ratio: 16 / 5;
  margin: -1px;
  z-index: 3000;
  position: relative;
}
.wave {
  overflow: hidden;
  position: relative;
  height: 81px;
}
.wave:after {
  content: '';
  position: absolute;
  will-change: transform;
  left: 0;
  top: 0;
  bottom: 0;
  right: -300vw;
  background: linear-gradient(to right, #3c83f6, #3c83f6);
  --mask: radial-gradient(75.15px at 50% 101.5px, #000 99%, #0000 101%)
      calc(50% - 70px) 0/140px 100%,
    radial-gradient(75.15px at 50% -66.5px, #0000 99%, #000 101%) 50% 35px/140px
      100% repeat-x;
  -webkit-mask: var(--mask);
  mask: var(--mask);
  animation: wave 30s linear infinite alternate;
}
@keyframes wave {
  100% {
    transform: translate3d(-300vw, 0, 0px);
  }
}


.wave-yellow {
    overflow: hidden;
    position: relative;
    height: 81px;
  }
  .wave-yellow:after {
    content: '';
    position: absolute;
    will-change: transform;
    left: 0;
    top: 0;
    bottom: 0;
    right: -300vw;
    background: linear-gradient(to right, #7ec72a, #7ec72a);
    --mask: radial-gradient(75.15px at 50% 101.5px, #000 99%, #0000 101%)
        calc(50% - 70px) 0/140px 100%,
      radial-gradient(75.15px at 50% -66.5px, #0000 99%, #000 101%) 50% 35px/140px
        100% repeat-x;
    -webkit-mask: var(--mask);
    mask: var(--mask);
    animation: wave-yellow 30s linear infinite alternate;
  }
  @keyframes wave-yellow {
    100% {
      transform: translate3d(-300vw, 0, 0px);
    }
  }
  