.Header {}

.topLogo {
    width: 100px;
    margin: 15px;
}

.walletbar {
    padding-left: 16px !important;
    padding-top: 16px;
    text-align: right;
    background: #0d2238;
    z-index: -1;

    div {
        height: 30vH;
    }
}