.Initial {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    
}

.mainLogo {
    width: 300px;
    margin: 15px auto;
}
.perfect-center {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.chip-cover {
    chip {
        width: 100%;
    }
}

.FontAwesomeIcon {
    margin-right: 10px;
}

.main-icons {
    position: fixed;
}