.Home {}

.perfect-center {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.perfect-bottom {
    display: flex;
    flex-wrap: wrap;
    align-items: end;
    justify-content: center;
}


.full-height {
    height: 100vH;
}

.mainlogo {
    width: 100%;
    h1 {
         color: #d1d0d0;
         font-weight: 100;
    }
}

.email-class, .password-class {
    border: 1px solid #121212;
}

.btn {
    margin: 30px;
}

.loginBox {
    background: rgba(19, 49, 72, 0.5);
    padding: 30px;
    border-radius: 10px;

}

@media (max-width : 1199px) {
    .full-height {
        height: auto;
    }
}