.Layout {}

.main-logo {
    width: 300px;
    border-radius: 30px;
    margin: 0px auto;
}

.initLogo {
    padding: 30px;
}