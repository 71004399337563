.Footer {}

.bn {
    position: fixed;
    width: 100%;
    bottom: 0;
    padding-bottom: 0px !important;
}

a {
    color: #e4b648 !important;
}