.Status {
    width: 100% !important;
}

/* Table Container */
/* Table Styles */
Table {
    width: 100%;
    border-collapse: collapse;
}

/* Header Styles */
Thead {
    background-color: #123148;
    color: white;
}

Th {
    padding: 12px 15px;
}

/* Body Styles */
Tbody Tr {
    background-color: #cccccc;
    transition: background-color 0.3s;
    color: #123148;
    text-align: center;
}

Tbody Tr:hover {
    background-color: #f1f1f1;
    /* Light gray on hover */
}

Td {
    padding: 12px 15px;
    border-bottom: 1px solid #dddddd;
}

.table-cover {
    margin-top: 110px;
    margin-bottom: 100px;
}

.task-cover {
    margin-top: 110px;
    margin-bottom: 100px;
}

/* Responsive Styles */
@media (max-width: 600px) {

    Th,
    Td {
        padding: 8px;
        font-size: 14px;
    }

    .table-cover {
        margin-top: 10px;
        margin-bottom: 100px;
    }
    
}