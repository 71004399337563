.Admin {
    height: 100vH;
    padding: 30px;
}

.perfect-center {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.register-form {
    margin-bottom: 30px;
}