.TimeTableDetails {
    /* Default styles (for desktops or large screens) */
    width: 100%;
    margin: auto;
    padding: 20px;
    font-size: 1rem;
    padding-top: 30px;
  }
  
  /* For tablets (landscape and portrait) */
  @media (max-width: 1024px) {
    .TimeTableDetails {
      width: 90%;
      padding: 15px;
      font-size: 0.95rem;
    }
  }
  
  /* For phones (large) */
  @media (max-width: 768px) {
    .TimeTableDetails {
      width: 95%;
      padding: 10px;
      font-size: 0.9rem;
    }
  }
  
  /* For phones (small) */
  @media (max-width: 480px) {
    .TimeTableDetails {
      width: 100%;
      padding: 8px;
      font-size: 0.85rem;
    }
  }

  .MuiDrawer-root {
    z-index: 1300 !important; /* Set a higher value for Drawer */
    
    .MuiDrawer-backdrop {
      background-color: transparent !important;  /* Set the backdrop to be transparent */
    }
  }
  
  